import clsx from "clsx";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "../Button";
import DoughnutChart from "../DoughnutChart";

import DashboardItem from "./DashboardItem";

interface Props {
  isLoading?: boolean;
  data: {
    offline: number;
    onlineNotUpdated: number;
    onlineUpdated: number;
  };
}

interface DoughnutChartDataSet {
  backgroundColors: string[];
  data: number[];
  labels: string[];
}

const DashboardTotalsItem: React.FC<Props> = ({ isLoading, data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [doughnutData, setDoughnutData] = React.useState<DoughnutChartDataSet>({
    backgroundColors: ["#64A58E", "#B44040", "#A9A9A9"],
    data: [data.onlineUpdated, data.onlineNotUpdated, data.offline],
    labels: [
      t("dashboard.devicesOnlineUpdated"),
      t("dashboard.devicesOnlineNotUpdated"),
      t("dashboard.devicesOffline"),
    ],
  });

  const totalDevices = useMemo(() => {
    return data.onlineNotUpdated + data.onlineUpdated + data.offline;
  }, [data]);

  useEffect(() => {
    setDoughnutData((prevData) => ({
      ...prevData,
      data: [data.onlineUpdated, data.onlineNotUpdated, data.offline],
    }));
  }, [data]);

  return (
    <DashboardItem
      title={t("dashboard.totalComputer")}
      subtitle={
        <span className="flex flex-row items-center text-azure">
          {t("common.computers")}: {totalDevices}
        </span>
      }
    >
      <div className="flex h-full w-full items-start justify-start px-4 py-4">
        {data.onlineNotUpdated === 0 &&
        data.onlineUpdated === 0 &&
        data.offline === 0 ? (
          <div className="mr my-auto flex h-[200px] w-full flex-row items-center justify-start gap-20">
            <div
              className={clsx(
                "flex h-[200px] w-[200px] flex-shrink-0 rounded-full bg-gray",
                isLoading && "animate-pulse",
              )}
            >
              <div className="m-auto h-[140px] w-[140px] rounded-full bg-white" />
            </div>

            <div className="flex w-full flex-col items-center justify-center gap-8">
              <span className="leading-8">
                {t("dashboard.noOperationalStatus")}
              </span>
              <div>
                <Button
                  onPress={() => {
                    navigate("/settings/configuration");
                  }}
                >
                  {t("dashboard.connectFirstDevice")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="my-auto flex h-[200px] w-full flex-row items-center justify-start gap-20">
            <DoughnutChart
              data={{
                datasets: [
                  {
                    backgroundColor: doughnutData.backgroundColors,
                    data: doughnutData.data,
                  },
                ],
                labels: doughnutData.labels,
              }}
            />
            <ul className="flex w-full flex-col items-start justify-start gap-4">
              {doughnutData.labels.map((label, index) => (
                <li key={index} className="flex items-center justify-start">
                  <span
                    className="mr-2 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-xs text-white"
                    style={{
                      backgroundColor: doughnutData.backgroundColors[index],
                    }}
                  ></span>
                  <span>
                    {label} ({Object.values(doughnutData.data)[index]})
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </DashboardItem>
  );
};
export default DashboardTotalsItem;
