import { CalendarDate } from "@internationalized/date";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "react-stately";

import { PolicyTypeEnum } from "../../enum/PolicyTypeEnum";
import useServerData from "../../hooks/useServerData";
import useTooltip from "../../hooks/useTooltip";
import { EventBus, EventBusEvents } from "../../utils/EventBus";
import { policyFields } from "../../utils/policyFields";
import Checkbox from "../Checkbox";
import { CheckboxGroup, CheckboxGroupItem } from "../CheckboxGroup";
import { ComboBox } from "../ComboBox";
import DatePicker from "../DatePicker";
import { Icon } from "../Icon";
import { MultiselectComboboxOption } from "../MultiSelectComboBox";
import NumberField from "../NumberField";
import { Radio, RadioGroup } from "../RadioGroup";
import { Select } from "../Select";
import TextArea from "../TextArea";
import TextField from "../TextField";

import { SecurityPolicyInputComboBoxButton } from "./SecurityPolicyInputComboBoxButton";
import { SecurityPolicyInputComboBoxList } from "./SecurityPolicyInputComboBoxList";
import { SecurityPolicyInputComboBoxStringTuple } from "./SecurityPolicyInputComboBoxStringTuple";
import { SecurityPolicyInputMultiSelect } from "./SecurityPolicyInputMultiselect";
import { SecurityPolicyInputNumberTuple } from "./SecurityPolicyInputNumberTuple";
import { SecurityPolicyInputStringList } from "./SecurityPolicyInputStringList";
import { SecurityPolicyInputStringTuple } from "./SecurityPolicyInputStringTuple";
import { SecurityPolicyInputTimespan } from "./SecurityPolicyInputTimespan";
import { SecurityPolicyInputTimespanTuple } from "./SecurityPolicyInputTimespanTuple";

interface SecurityPolicyInputsProps {
  policyType: PolicyTypeEnum;
  onChange: (fieldId: string, newValue: any) => void;
  onInputAction: (fieldId: string) => void;
  initialValue: { [key: string]: any };
  isLoading: boolean;
  errors?: Record<string, string>;
  disabledFieldIds?: string[];
  loadingFieldIds?: string[];
  isReadOnly?: boolean;
  invalidFields: string[];
  hiddenFieldIds?: string[];
}

/**
 * Updates the filtered options for a multiselect field based on a search query.
 *
 * @param {string} fieldId - The ID of the multiselect field.
 * @param {string} query - The search query.
 * @returns {void}
 */
export const SecurityPolicyInputs: React.FC<SecurityPolicyInputsProps> = ({
  policyType,
  onChange,
  initialValue,
  isLoading,
  errors,
  disabledFieldIds,
  hiddenFieldIds,
  isReadOnly,
  invalidFields,
  onInputAction,
  loadingFieldIds,
}) => {
  const { settings } = useServerData({ forceFetchSettings: true });
  const { t } = useTranslation();
  const [rerenderKey, setRerenderKey] = useState(0);
  const { showTooltip, hideTooltip } = useTooltip();

  /**
   * @desc Convert a string to a CalendarDate object
   * @param dateString
   */
  function stringToCalendarDate(dateString: string): CalendarDate | null {
    if (!dateString || !/^\d{4}-\d{2}-\d{2}/.test(dateString)) {
      return null;
    }

    const dateOnly = dateString.split("T")[0];
    const [year, month, day] = dateOnly.split("-").map(Number);

    if (year <= 0 || isNaN(year) || isNaN(month) || isNaN(day)) {
      return null;
    }

    if (month < 1 || month > 12) {
      return null;
    }

    if (day < 1 || day > 31) {
      return null;
    }

    return new CalendarDate(year, month, day);
  }

  /**
   * Returns an array of field objects with updated options based on the PolicyTypeEnum and settings.
   *
   * @returns {Array} The array of field objects.
   *
   * @param {Function} useMemo - A memoization function.
   * @param {String} PolicyTypeEnum - The PolicyTypeEnum.
   * @param {Object} settings - The settings object.
   */
  const fields = useMemo(() => {
    return policyFields[policyType]
      ?.filter((field) => !hiddenFieldIds?.includes(field.id))
      .map((field) => {
        let newOptions = field.options;
        switch (field.id) {
          case "extensions":
            ["office", "image", "multimedia", "cad", "executable"].forEach(
              (type) => {
                newOptions = newOptions?.concat(
                  settings?.fileTypes[type].map((ext: string) => ({
                    id: ext,
                    sectionId: type,
                    sectionLabel: t(`fileTypes.${type}`),
                    value: ext,
                  })),
                );
              },
            );
            break;
          case "certificates":
            {
              if (!settings?.certifiedApplications) return field;
              const certifiedApplicationsArray = Object.values(
                settings?.certifiedApplications,
              );
              newOptions = certifiedApplicationsArray?.map(
                (certifiedApplication: any) => ({
                  id: certifiedApplication.id,
                  value: certifiedApplication.name,
                }),
              );
            }
            break;
          case "exceptInclude":
          case "exceptExclude":
            {
              // Set options for exceptInclude and exceptExclude fields based on the selected certificates
              let mappedInitialValue: number[] = [];
              if (field.id === "exceptInclude") {
                mappedInitialValue = initialValue["exceptExclude"]?.map(
                  (item: { item1: any }) => parseInt(item.item1),
                );
              } else {
                mappedInitialValue = initialValue["exceptInclude"]?.map(
                  (item: { item1: any }) => parseInt(item.item1),
                );
              }

              newOptions = initialValue.certificates?.filter(
                (certifiedApplication: any) => {
                  return !mappedInitialValue?.includes(certifiedApplication.id);
                },
              );
            }
            break;
        }
        return { ...field, options: newOptions };
      });
  }, [
    policyType,
    settings?.certifiedApplications,
    settings?.fileTypes,
    initialValue?.apiKey,
    initialValue?.certificates,
    initialValue?.extensions,
    initialValue?.exceptInclude,
    initialValue?.exceptExclude,
    rerenderKey,
    hiddenFieldIds,
  ]);

  /**
   * @desc Listens for CREATE_NOTIFICATION events and calls createNotification
   */
  useEffect(() => {
    const handler = (data: {
      fieldId: string;
      options: MultiselectComboboxOption[];
    }) => {
      const field = fields?.find((field) => field.id === data.fieldId);
      if (field) {
        field.options = data.options;
        setRerenderKey(Math.random());
      }
    };
    EventBus.on(EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE, handler);
    return () => {
      EventBus.off(EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE, handler);
    };
  }, []);

  return (
    <div className="flex w-full flex-col gap-2">
      {fields?.length === 0 && (
        <div className="flex items-center justify-center py-4">
          <span className="text-base text-dark-gray">
            {t("common.noParametersToSet")}
          </span>
        </div>
      )}
      {fields?.map((field) => {
        return (
          <div key={field.id} className="grid w-full grid-cols-1 gap-1">
            {field.description && (
              <label className="pt-4 text-extra-dark-gray">
                {field.description}
              </label>
            )}

            {field.inputType === "label" && (
              <label
                className={clsx(
                  "py-3 text-lg text-dark-blue",
                  !!field.className && field.className,
                )}
              >
                {field.label}
              </label>
            )}

            {field.type === "divider" && (
              <div className="h-0.5 border-b border-light-gray" />
            )}

            {field.hasOptionExcept && (
              <>
                <Select
                  isDisabled={
                    isLoading ||
                    disabledFieldIds?.includes(field.id) ||
                    isReadOnly
                  }
                  variant="text"
                  id="optionExcept"
                  name="optionExcept"
                  aria-label="Assign to all but"
                  selectedKey={initialValue.optionExcept === false ? "0" : "1"}
                  isRequired={field.required || false}
                  onSelectionChange={(e) => {
                    onChange("optionExcept", e !== "0");
                  }}
                >
                  <Item key={"1"} aria-label={t("common.assignToAllBut")}>
                    {t("common.assignToAllBut")}
                  </Item>
                  <Item key={"0"} aria-label={t("common.assignTo")}>
                    {t("common.assignTo")}
                  </Item>
                </Select>
              </>
            )}

            {field.inputType === "textfield" && (
              <TextField
                isReadOnly={isReadOnly}
                isDisabled={isLoading || disabledFieldIds?.includes(field.id)}
                id={field.id}
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
                label={field.label}
                value={!initialValue || initialValue[field.id] || ""}
                isRequired={field.required || false}
                required={field.required || false}
                onChange={(e) => {
                  if (field.type === "number") {
                    onChange(field.id, parseInt(e));
                  } else {
                    onChange(field.id, e);
                  }
                }}
              />
            )}

            {field.inputType === "textarea" && (
              <TextArea
                onFocusSelectText={true}
                isReadOnly={isReadOnly}
                isDisabled={isLoading || disabledFieldIds?.includes(field.id)}
                id={field.id}
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
                label={field.label}
                value={!initialValue || initialValue[field.id] || ""}
                isRequired={field.required || false}
                required={field.required || false}
                onChange={(e) => {
                  if (field.type === "number") {
                    onChange(field.id, parseInt(e));
                  } else {
                    onChange(field.id, e);
                  }
                }}
              />
            )}

            {field.inputType === "numberfield" && (
              <NumberField
                isReadOnly={isReadOnly}
                isDisabled={isLoading || disabledFieldIds?.includes(field.id)}
                id={field.id}
                placeholder={field.placeholder}
                label={field.label}
                value={!initialValue || initialValue[field.id] || ""}
                minValue={field.minValue || 0}
                maxValue={field.maxValue || 100}
                isRequired={field.required || false}
                required={field.required || false}
                formatOptions={{
                  maximumFractionDigits: 0,
                  useGrouping: false,
                }}
                onChange={(e) => {
                  onChange(field.id, e);
                }}
              />
            )}

            {field.inputType === "numbertuple" && (
              <SecurityPolicyInputNumberTuple
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                onChange={(e) => onChange(field.id, e)}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {field.inputType === "combobox" && field?.options && (
              <ComboBox
                isReadOnly={field.readOnly || isReadOnly}
                isDisabled={isLoading || disabledFieldIds?.includes(field.id)}
                isLoading={
                  isLoading || loadingFieldIds?.includes(field.id) || false
                }
                id={field.id}
                selectedKey={String(initialValue?.[field.id] ?? "")}
                aria-label={field.label}
                label={field.label}
                onSelectionChange={(value) => {
                  onChange(field.id, value);
                }}
              >
                {field.options.map((option) => (
                  <Item key={option.id} aria-label={option.value}>
                    {option.value}
                  </Item>
                ))}
              </ComboBox>
            )}

            {field.inputType === "timespan" && (
              <SecurityPolicyInputTimespan
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                onChange={(e) => {
                  onChange(field.id, e);
                }}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {field.inputType === "checkbox" && (
              <div className="flex flex-row items-center gap-1">
                <Checkbox
                  isReadOnly={isReadOnly}
                  isDisabled={isLoading || disabledFieldIds?.includes(field.id)}
                  value={!initialValue || initialValue[field.id] || false}
                  isSelected={!initialValue || initialValue[field.id] || false}
                  aria-label={field.label}
                  onChange={(e) => onChange(field.id, e)}
                >
                  {field.label}
                </Checkbox>
                {field?.info && (
                  <div
                    onMouseEnter={(e) =>
                      showTooltip(
                        <div className="max-w-[250px] whitespace-normal break-words">
                          {field.info}
                        </div>,
                        e,
                        true,
                        250,
                      )
                    }
                    onMouseLeave={hideTooltip}
                  >
                    <Icon
                      name="InfoIcon"
                      className="h-4 w-4 text-extra-dark-gray"
                    />
                  </div>
                )}
              </div>
            )}

            {field.inputType === "stringlist" && (
              <SecurityPolicyInputStringList
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                onChange={(e) => onChange(field.id, e)}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
                isDisabled={disabledFieldIds?.includes(field.id)}
                invalidFields={invalidFields}
              />
            )}

            {field.inputType === "stringtuple" && (
              <SecurityPolicyInputStringTuple
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                onChange={(e) => onChange(field.id, e)}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {field.inputType === "comboboxstringtuple" && (
              <SecurityPolicyInputComboBoxStringTuple
                invalidFields={invalidFields}
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                isDisabled={disabledFieldIds?.includes(field.id)}
                onChange={(e) => onChange(field.id, e)}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {field.inputType === "comboboxlist" && (
              <SecurityPolicyInputComboBoxList
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                onChange={(e) => onChange(field.id, e)}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {field.inputType === "multiselect" && (
              <SecurityPolicyInputMultiSelect
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                isDisabled={disabledFieldIds?.includes(field.id)}
                onChange={(e) => onChange(field.id, e)}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {field.inputType === "datepicker" && (
              <DatePicker
                onChange={(e) => {
                  onChange(
                    field.id,
                    new CalendarDate(e.year, e.month, e.day).toString(),
                  );
                }}
                isReadOnly={isReadOnly}
                isDisabled={isLoading}
                value={
                  stringToCalendarDate(initialValue[field.id] || "") || null
                }
                label={field.label || ""}
                aria-label={field.label}
              />
            )}

            {field.inputType === "checkboxgroup" && (
              <>
                {field.options && field.options.length > 0 && (
                  <CheckboxGroup
                    isDisabled={isLoading}
                    isReadOnly={isReadOnly}
                    onChange={(selected) => {
                      onChange(field.id, selected);
                    }}
                    aria-label={field.label}
                    aria-required={field.required || false}
                    aria-labelledby={field.label}
                    value={initialValue[field.id]}
                    name={field.name}
                  >
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      {field.options.map((item) => (
                        <CheckboxGroupItem
                          key={item.id}
                          aria-label={item.value}
                          value={item.id as string}
                        >
                          {item.value}
                        </CheckboxGroupItem>
                      ))}
                    </div>
                  </CheckboxGroup>
                )}
              </>
            )}

            {field.inputType === "timespantuple" && (
              <SecurityPolicyInputTimespanTuple
                isLoading={isLoading}
                onChange={(e) => {
                  onChange(field.id, e);
                }}
                isReadOnly={isReadOnly}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {field.inputType === "radiogroup" && (
              <RadioGroup
                aria-label={field.label || field.description || ""}
                value={initialValue[field.id]?.toString()}
                onChange={(value) => onChange(field.id, value)}
                label={field.label || ""}
                errorMessage="Error message"
              >
                {field?.options?.map((option) => (
                  <Radio key={option.id} value={option.id.toString()}>
                    {option.value}
                  </Radio>
                ))}
              </RadioGroup>
            )}

            {field.inputType === "comboboxbutton" && (
              <SecurityPolicyInputComboBoxButton
                onAction={() => {
                  onInputAction(field.id);
                }}
                isLoading={
                  isLoading || loadingFieldIds?.includes(field.id) || false
                }
                isReadOnly={isReadOnly}
                isDisabled={disabledFieldIds?.includes(field.id)}
                onChange={(e) => onChange(field.id, e)}
                field={field}
                value={!initialValue || initialValue[field.id] || []}
              />
            )}

            {errors && errors[field.id] && (
              <p className="p-2 text-xs text-red">{errors[field.id]}</p>
            )}
          </div>
        );
      })}
    </div>
  );
};
