import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useDateFormatter } from "react-aria";

import { useSignalR } from "../contexts/SignalRContext";
import { SignalREventTypeEnum } from "../enum/SignalREventTypeEnum";
import Policy from "../interfaces/Policy";
import { TimespanOptionKey } from "../interfaces/TimespanOption";
import { AccountService } from "../services/accountService";
import { policies } from "../utils/policies";
import { getStorageItem, storeStorageItem } from "../utils/storage";
import { convertDateToTicks, getTimestampOptionDates } from "../utils/utils";

//const activityService = new ActivityService();
const accountService = new AccountService();

export interface PolicyReport {
  high: number;
  medium: number;
  low: number;
  lastActivity: string;
  policy: Policy;
  securityPoliciesCount: number;
  highRead: boolean;
  mediumRead: boolean;
  lowRead: boolean;
}

interface DashboardData {
  summary: {
    low: number;
    medium: number;
    high: number;
  };
  computers: {
    offline: number;
    onlineNotUpdated: number;
    onlineUpdated: number;
  };
  policies: PolicyReport[];
}

export interface DashboardFilterState {
  count: number;
  offset: number;
  dateStart: number;
  dateEnd: number;
  sortCol: string;
  sortDir: string;
  readonly: boolean;
  timespanOption: TimespanOptionKey;
}

let showIsLoading = true;

function useDashboard() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //const [userActivities, setUserActivities] = useState<UserActivity[]>([]);
  const { signalRConnection } = useSignalR();
  const initialFilters: DashboardFilterState = (() => {
    const storedFilters = getStorageItem(
      "sessionStorage",
      "dashboardFilters",
    ) as DashboardFilterState;

    const { from, to } = getTimestampOptionDates(
      storedFilters?.timespanOption || TimespanOptionKey.LastHour,
    );
    const filters: Partial<DashboardFilterState> = storedFilters || {
      activityType: [],
      count: 10,
      dateEnd: convertDateToTicks(to),
      dateStart: convertDateToTicks(from),
      offset: 0,
      readonly: true,
      search: undefined,
      sortCol: "createdOn",
      sortDir: "descending",
      timespanOption: TimespanOptionKey.LastTwentyFourHours,
      userID: [],
    };
    if (
      storedFilters?.dateStart &&
      storedFilters?.dateEnd &&
      storedFilters.timespanOption !== "custom"
    ) {
      filters.dateStart = convertDateToTicks(from);
      filters.dateEnd = convertDateToTicks(to);
    }
    return filters as DashboardFilterState;
  })();
  const [filters, setFilters] = useState<DashboardFilterState>(initialFilters);

  const [dashboardData, setDashboardData] = useState<DashboardData>({
    computers: {
      offline: 0,
      onlineNotUpdated: 0,
      onlineUpdated: 0,
    },
    policies: [],
    summary: {
      high: 0,
      low: 0,
      medium: 0,
    },
  });

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * Retrieves the user activities.
   *
   * @function
   * @async
   * @name getUserActivities
   * @throws {Error} An error occurred while retrieving user activities.
   * @returns {Promise<void>} Promise object that resolves when user activities have been retrieved successfully.
   *
   */
  /*const getUserActivities = useCallback(async () => {
    setIsLoading(true);
    activityService
      .getUserActivities(
        qs.stringify(filters, {
          filter: (prefix, value) => (value === "" ? undefined : value),
          skipNulls: true,
        }),
      )
      .then((res) => {
        setUserActivities(res.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [filters]);*/

  /**
   * Retrieves the dashboard data from the server.
   *
   * @function getDashboardData
   * @async
   * @returns {Promise<void>}
   */
  const getDashboardData = useCallback(async () => {
    if (showIsLoading) setIsLoading(true);

    accountService
      .getDashboardData(
        qs.stringify(filters, {
          filter: (prefix, value) => (value === "" ? undefined : value),
          skipNulls: true,
        }),
      )
      .then((res) => {
        const parsedPolicies: PolicyReport[] = [];
        policies.map((policy) => {
          // Camel case the policy name
          const foundPolicy = res.details[policy.key];
          if (foundPolicy) {
            parsedPolicies.push({
              high: foundPolicy.high.total,
              highRead: foundPolicy.high.read,
              lastActivity:
                foundPolicy.high.lastActivity ||
                foundPolicy.medium.lastActivity ||
                foundPolicy.low.lastActivity ||
                "",
              low: foundPolicy.low.total,
              lowRead: foundPolicy.low.read,
              medium: foundPolicy.medium.total,
              mediumRead: foundPolicy.medium.read,
              policy,
              securityPoliciesCount:
                foundPolicy.high.policies +
                foundPolicy.low.policies +
                foundPolicy.medium.policies,
            });
          }
        });
        setDashboardData({
          computers: res.computers,
          policies: parsedPolicies,
          summary: res.summary,
        });
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    Promise.all([getDashboardData()]).then(() => {});
  }, [getDashboardData]);

  useEffect(() => {
    storeStorageItem("sessionStorage", "dashboardFilters", filters);
  }, [filters]);

  useEffect(() => {
    if (signalRConnection) {
      let debounceTimer: number | undefined;
      let maxTimeoutTimer: number | undefined;
      const MAX_TIMEOUT = 30000; // 30 seconds
      let eventStartTime: number | undefined;
      showIsLoading = false;
      const handleReceiveMessage = () => {
        const now = Date.now();

        if (debounceTimer) clearTimeout(debounceTimer);

        if (!eventStartTime) {
          eventStartTime = now;

          maxTimeoutTimer = window.setTimeout(() => {
            if (filters.timespanOption !== TimespanOptionKey.Custom) {
              const { from, to } = getTimestampOptionDates(
                filters.timespanOption,
              );
              setFilters((prevFilters) => ({
                ...prevFilters,
                dateEnd: convertDateToTicks(to),
                dateStart: convertDateToTicks(from),
              }));
            } else {
              Promise.all([getDashboardData()]).then(() => {});
            }
            eventStartTime = undefined;
          }, MAX_TIMEOUT);
        }

        // Set a new debounce timeout
        debounceTimer = window.setTimeout(() => {
          // Esegui le chiamate API dopo il periodo di debounce
          if (filters.timespanOption !== TimespanOptionKey.Custom) {
            const { from, to } = getTimestampOptionDates(
              filters.timespanOption,
            );
            setFilters((prevFilters) => ({
              ...prevFilters,
              dateEnd: convertDateToTicks(to),
              dateStart: convertDateToTicks(from),
            }));
          } else {
            Promise.all([getDashboardData()]).then(() => {});
          }
          if (maxTimeoutTimer) clearTimeout(maxTimeoutTimer); // Delete the max timeout timer because the event has been handled
          eventStartTime = undefined; // Reset the event start time
        }, MAX_TIMEOUT);
      };

      signalRConnection.on(
        SignalREventTypeEnum.notifiedNewActivity,
        handleReceiveMessage,
      );
      signalRConnection.on(
        SignalREventTypeEnum.changedComputerStatus,
        handleReceiveMessage,
      );

      return () => {
        signalRConnection.off(
          SignalREventTypeEnum.notifiedNewActivity,
          handleReceiveMessage,
        );
        signalRConnection.off(
          SignalREventTypeEnum.changedComputerStatus,
          handleReceiveMessage,
        );

        if (debounceTimer) clearTimeout(debounceTimer);
        if (maxTimeoutTimer) clearTimeout(maxTimeoutTimer);
      };
    }
  }, [signalRConnection]);

  return {
    dashboardData,
    dateFormatter,
    filters,
    isLoading,
    setFilters,
    //userActivities,
  };
}

export default useDashboard;
