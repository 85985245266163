import React from "react";

import DashboardCountersItem from "../components/dashboard/DashboardCountersItem";
import DashboardReportItem from "../components/dashboard/DashboardReportItem";
import DashboardTotalsItem from "../components/dashboard/DashboardTotalsItem";
import useDashboard from "../hooks/useDashboard";

export const Dashboard: React.FC = () => {
  const { dateFormatter, isLoading, dashboardData, setFilters, filters } =
    useDashboard();

  return (
    <div className="flex min-h-full flex-col gap-4 xl:flex-row">
      <div className="flex w-full flex-1 flex-col gap-6 xl:max-w-[650px]">
        <div className="lg:max-h-96">
          <DashboardCountersItem
            data={dashboardData.summary}
            filters={filters}
            onFilterChange={(e) => {
              setFilters({ ...filters, ...e });
            }}
          />
        </div>

        {/*<DashboardRecentAlertsItem
          isLoading={isLoading}
          items={userActivities}
          dateFormatter={dateFormatter}
        />*/}
        <div className="h-full">
          <DashboardTotalsItem
            isLoading={isLoading}
            data={dashboardData.computers}
          />
        </div>
      </div>
      <div className="w-full flex-1">
        <DashboardReportItem
          filters={filters}
          data={dashboardData.policies}
          dateFormatter={dateFormatter}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
