import { ComboBoxProps as BaseComboBoxProps } from "@react-types/combobox";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useButton, useComboBox, useFilter } from "react-aria";
import { createPortal } from "react-dom";
import { useComboBoxState } from "react-stately";

import { Icon } from "./Icon";
import ListBox from "./ListBox";
import ProgressCircle from "./ProgressCircle";

export { Item } from "react-stately";

interface ComboBoxProps<T extends object> extends BaseComboBoxProps<T> {
  required?: boolean;
  isLoading?: boolean;
  id: string;
  containerClassName?: string;
}

export function ComboBox<T extends object>(props: ComboBoxProps<T>) {
  const { contains } = useFilter({ sensitivity: "base" });
  const state = useComboBoxState({ ...props, defaultFilter: contains });
  const [isListOpenAbove, setIsListOpenAbove] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { id } = props;
  const {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
  } = useComboBox(
    {
      ...props,
      buttonRef,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  const { buttonProps } = useButton(triggerProps, buttonRef);

  /**
   * Sets focus on the specified HTML label element.
   *
   * @param {React.MouseEvent<HTMLLabelElement>} event - The mouse event triggered by clicking the label.
   */
  const focusInput = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (state.isOpen && ref.current) {
      const rect = ref.current?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (rect) {
        const isInputLowerThanHalfScreen = rect.top > windowHeight / 2;
        setIsListOpenAbove(isInputLowerThanHalfScreen);
      }
    }
  }, [state.isOpen]);

  useEffect(() => {
    if (state.isOpen && ref.current && popoverRef.current) {
      const triggerRect = ref.current.getBoundingClientRect();
      const listBoxHeight = popoverRef.current.offsetHeight;
      const spaceAbove = triggerRect.top;
      const spaceBelow = window.innerHeight - triggerRect.bottom;
      const hasEnoughSpaceBelow = spaceBelow >= listBoxHeight;

      setIsListOpenAbove(!hasEnoughSpaceBelow);

      const listBoxStyle = popoverRef.current.style;
      listBoxStyle.width = `${triggerRect.width}px`;
      listBoxStyle.left = `${triggerRect.left}px`;

      if (!hasEnoughSpaceBelow && spaceAbove >= listBoxHeight) {
        listBoxStyle.top = "";
        listBoxStyle.bottom = `${window.innerHeight - triggerRect.top + 10}px`;
      } else {
        listBoxStyle.bottom = "";
        listBoxStyle.top = `${triggerRect.bottom + 10}px`;
      }
    }
  }, [state.isOpen, ref, popoverRef]);

  /**
   * @description Handles the scroll event on the window to keep the dropdown in the right position.
   */
  useEffect(() => {
    const handleElementScroll = () => {
      if (!state.isOpen || !ref.current || !popoverRef.current) {
        return;
      }

      const rect = ref.current.getBoundingClientRect();
      const ulHeight = popoverRef.current.offsetHeight;
      const shouldOpenAbove =
        window.innerHeight - rect.bottom < ulHeight && rect.top > ulHeight;

      setIsListOpenAbove(shouldOpenAbove);

      popoverRef.current.style.width = `${rect.width}px`;
      popoverRef.current.style.left = `${window.pageXOffset + rect.left}px`;

      if (shouldOpenAbove) {
        popoverRef.current.style.top = "auto";
        popoverRef.current.style.bottom = `${
          window.innerHeight - rect.top + 10
        }px`;
      } else {
        popoverRef.current.style.bottom = "auto";
        popoverRef.current.style.top = `${
          window.pageYOffset + rect.bottom + 10
        }px`;
      }
    };

    document.addEventListener("scroll", handleElementScroll, true);
    document.addEventListener("resize", handleElementScroll, true);

    return () => {
      document.removeEventListener("scroll", handleElementScroll, true);
      document.removeEventListener("resize", handleElementScroll, true);
    };
  }, [state.isOpen, ref, popoverRef, isListOpenAbove]);

  return (
    <div
      ref={ref}
      className={clsx(
        "relative w-full rounded-md",
        props.containerClassName,
        props.isReadOnly && "bg-light-gray",
        props.isDisabled && "pointer-events-none opacity-50",
      )}
    >
      <div className="relative flex h-[40px] w-full flex-row items-center gap-2 rounded-[6px] border border-gray pr-3.5">
        <input
          {...inputProps}
          ref={inputRef}
          className="peer h-[40px] w-full rounded-[6px] bg-transparent px-3 pt-3.5 font-sans text-sm font-normal text-extra-dark-gray outline outline-0 transition-all duration-100 ease-in-out focus:outline-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
          required={props.required}
          placeholder=" "
        />
        {props.label && (
          <label
            htmlFor={id}
            onClick={focusInput}
            className="peer-focus:text-blue-600 peer-focus:dark:text-blue-500 absolute start-3 top-3 z-10 origin-[0] -translate-y-2.5 scale-75 transform text-sm text-dark-gray duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2.5 peer-focus:scale-75 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4"
          >
            {props.label}
          </label>
        )}
        <button
          {...buttonProps}
          ref={buttonRef}
          className={clsx(
            "transition-all duration-100 ease-in-out",
            props.isLoading
              ? "pointer-events-none opacity-50"
              : "hover:opacity-90 active:opacity-80",
          )}
        >
          {props.isLoading ? (
            <div className="transform">
              <ProgressCircle
                aria-label="Loading..."
                aria-labelledby="Loading..."
                aria-loading="Loading..."
                isIndeterminate
              />
            </div>
          ) : (
            <Icon
              name="ArrowDropDownIcon"
              className={clsx(
                "m-auto w-full transition-all duration-200 ease-in-out",
                state.isOpen ? "rotate-180" : "rotate-0",
              )}
            />
          )}
        </button>
      </div>
      {state.isOpen &&
        createPortal(
          <div
            ref={popoverRef}
            className={clsx(
              "fixed z-50 max-h-80 overflow-y-auto rounded-[6px] border border-gray bg-white p-3 shadow-md",
              isListOpenAbove && "bottom-16 top-auto",
              state.isOpen ? "visible" : "hidden",
            )}
          >
            <ListBox
              disabledKeys={props.disabledKeys}
              {...listBoxProps}
              listBoxRef={listBoxRef}
              state={state}
            />
          </div>,
          document.getElementById("select-root")!,
        )}
    </div>
  );
}
