import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import { useOverlayTriggerState } from "react-stately";

import { TableAction } from "../components/TableActionsBar";
import { NotificationTypes } from "../contexts/NotificationContext";
import { StorageItemKeyEnum } from "../enum/StorageItemKeyEnum";
import { UserActivitiesGroupingCriteriaEnum } from "../enum/UserActivitiesGroupingCriteriaEnum";
import Computer from "../interfaces/Computer";
import PolicySeverity from "../interfaces/PolicySeverity";
import RouteNotification from "../interfaces/RouteNotification";
import { TimespanOptionKey } from "../interfaces/TimespanOption";
import User from "../interfaces/User";
import UserActivity from "../interfaces/UserActivity";
import UserActivityType from "../interfaces/UserActivityType";
import { ActivityService } from "../services/activityService";
import { policySeverities } from "../utils/policySeverities";
import { getStorageItem, storeStorageItem } from "../utils/storage";
import { userActivityTypes } from "../utils/userActivityTypes";
import {
  convertDateToTicks,
  getTimestampOptionDates,
  transformObjectArraysToArrayOfIds,
} from "../utils/utils";

import useGroupedUserActivitiesTab from "./useGroupedUserActivitiesTab";
import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const activityService = new ActivityService();

interface FilterState {
  activityType?: UserActivityType[];
  computerID?: Computer[];
  count: number;
  dateEnd: number;
  dateStart: number;
  offset: number;
  search?: string;
  userID?: User[];
  sort?: SortDescriptor;
  severity?: PolicySeverity[];
  timespanOption: TimespanOptionKey;
  notRead: boolean;
}

function useUserActivitiesTab() {
  const [activities, setActivities] = useState<UserActivity[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedActivity, setSelectedActivity] = useState<UserActivity>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const activityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [notificationsToRead, setNotificationsToRead] = useState<number>(0);
  const [resizablePanelWidth, setResizablePanelWidth] = useState<number>(() => {
    const savedState = getStorageItem(
      "sessionStorage",
      StorageItemKeyEnum.userActivitiesResizablePanelWidth,
    );
    return savedState !== null ? parseInt(savedState.toString()) : 300;
  });

  const initialFilters: FilterState = (() => {
    const storedFilters = getStorageItem(
      "sessionStorage",
      "userActivitiesFilters",
    ) as FilterState;

    const { from, to } = getTimestampOptionDates(
      storedFilters?.timespanOption || TimespanOptionKey.LastTwentyFourHours,
    );

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const filters: Partial<FilterState> = storedFilters || {
      activityType: [],
      computerID: [],
      count: 15,
      dateEnd: convertDateToTicks(to),
      dateStart: convertDateToTicks(from),
      notRead: false,
      offset: 0,
      search: undefined,
      severity: [],
      timespanOption: TimespanOptionKey.LastTwentyFourHours,
      userID: [],
    };

    if (
      storedFilters?.dateStart &&
      storedFilters?.dateEnd &&
      storedFilters.timespanOption !== "custom"
    ) {
      filters.dateStart = convertDateToTicks(from);
      filters.dateEnd = convertDateToTicks(to);
    }

    if (queryParams.dateStart) {
      filters.dateStart = Number(queryParams.dateStart);
    }

    if (queryParams.dateEnd) {
      filters.dateEnd = Number(queryParams.dateEnd);
    }

    if (queryParams.timespanOption) {
      filters.timespanOption = queryParams.timespanOption as TimespanOptionKey;
    }

    if (queryParams.notRead) {
      filters.notRead = queryParams.notRead === "true";
    }

    if (queryParams.severity) {
      policySeverities.find((severity) => {
        if (severity.key === queryParams.severity) {
          filters.severity = [severity];
          return true;
        }
        return false;
      });
    }

    if (queryParams.policyID) {
      if (
        Array.isArray(queryParams.policyID) &&
        queryParams.policyID.length > 0
      ) {
        filters.activityType = userActivityTypes.filter((activityType) => {
          if (typeof queryParams.policyID === "string") {
            return queryParams.policyID.includes(
              activityType?.policy?.id.toString() || "",
            );
          } else if (Array.isArray(queryParams.policyID)) {
            return (queryParams.policyID as string[]).includes(
              activityType?.policy?.id.toString() || "",
            );
          }
          return false;
        });
      } else {
        const foundActivityTypeByPolicy = userActivityTypes.find(
          (activityType) => {
            return activityType?.policy?.id.toString() === queryParams.policyID;
          },
        );
        if (foundActivityTypeByPolicy) {
          filters.activityType = [foundActivityTypeByPolicy];
        }
      }
    }

    return filters as FilterState;
  })();
  const [filters, setFilters] = useState<FilterState>(initialFilters);

  const detailModalState = useOverlayTriggerState({});
  const { t } = useTranslation();
  const { showTooltip, hideTooltip } = useTooltip();
  const { createNotification } = useNotifications();
  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  const {
    getGroupedPolicyLevel0,
    handleTreeItemToggle,
    groupingCriteria,
    isLoadingTreeItem,
    isLoadingTree,
    treeItems,
    setGroupingCriteria,
    groupedTreeItemsTableFields,
    groupedTotal,
    groupedFilters,
    setGroupedFilters,
    groupedActionModalState,
    handleGroupedItemMenuAction,
    selectedGroupedItem,
    handleGroupedModalActionConfirm,
    handleGroupedTableRowAction,
    currentOpenNode,
    currentOpenNodeParentTableActions,
  } = useGroupedUserActivitiesTab({
    initialFilters: filters,
  });

  /**
   * Returns a string representation of the filters object by performing several transformations and using query string parameters.
   *
   * @returns {string} The stringified filters object
   */
  const getStringifiesFilters = (): string => {
    const updatedFilters = structuredClone(filters);

    if (filters.timespanOption !== TimespanOptionKey.Custom) {
      const { from, to } = getTimestampOptionDates(filters.timespanOption);
      updatedFilters.dateEnd = convertDateToTicks(to);
      updatedFilters.dateStart = convertDateToTicks(from);
    }

    const transformedFilters =
      transformObjectArraysToArrayOfIds(updatedFilters);

    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    return qs.stringify(transformedFilters, {
      filter: (prefix, value) => (value === "" ? undefined : value),
      skipNulls: true,
    });
  };

  /**
   * @desc Fetch users
   */
  const getUserActivities = useCallback(async () => {
    // Delete the previous timeout
    if (activityTimeoutRef.current) {
      clearTimeout(activityTimeoutRef.current);
    }

    setIsLoading(true);

    try {
      const res = await activityService.getUserActivities(
        getStringifiesFilters(),
      );

      const toRead = res.toRead;
      setActivities(res.data);
      setTotal(res.total);
      setNotificationsToRead(toRead);

      // Get the routes with notifications for update counter if necessary
      const routesWithNotification = getStorageItem(
        "sessionStorage",
        StorageItemKeyEnum.routesWithNotifications,
      ) as RouteNotification[];

      if (
        routesWithNotification?.find(
          (route: RouteNotification) => route.key !== "activities",
        ) &&
        toRead > 0
      ) {
        storeStorageItem(
          "sessionStorage",
          StorageItemKeyEnum.routesWithNotifications,
          routesWithNotification?.filter(
            (route: RouteNotification) => route.key !== "activities",
          ),
        );
      }

      activityTimeoutRef.current = setTimeout(() => {
        const readActivities = res?.data?.map((activity: UserActivity) => ({
          ...activity,
          read: true,
        }));
        setActivities(readActivities);
      }, 4000);
    } catch (error) {
      createNotification(
        t("errors.genericFetchError", {
          resource: t("common.activities"),
        }),
        NotificationTypes.DANGER,
      );
    } finally {
      setIsLoading(false);
    }
  }, [createNotification, filters, t]);

  /**
   * Handles the click event on a detail element.
   *
   * @param {UserActivity} element - The user activity element clicked.
   *
   * @returns {void}
   */
  const handleDetailClick = (element: UserActivity): void => {
    setSelectedActivity(element);
    detailModalState.open();
  };

  /**
   * Resets the advanced filters by clearing the values for the activityType, computerID, groupComputerID, policyID, and userID properties in the filters state.
   * @function
   * @name handleResetAdvancedFilters
   * @return {void}
   */
  const handleResetAdvancedFilters = (): void => {
    setFilters((prevFilters: FilterState) => ({
      ...prevFilters,
      activityType: [],
      computerID: [],
      groupComputerID: [],
      offset: 0,
      severity: [],
      userID: [],
    }));
  };

  /**
   * Sets the grouping criteria for user activities and executes the corresponding actions.
   *
   * @param {UserActivitiesGroupingCriteriaEnum} criteria - The grouping criteria to apply.
   */
  const handleGroupingCriteriaChange = (
    criteria: UserActivitiesGroupingCriteriaEnum,
  ) => {
    setGroupingCriteria(criteria);
  };

  /**
   * Calculates the count of applied filters.
   *
   * @function
   * @returns {number} The count of applied filters.
   */
  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    Object.entries(filters).forEach(([, value]) => {
      if (Array.isArray(value)) {
        count += value.length;
      }
    });
    return count;
  }, [filters]);

  /**
   * Represents a variable that stores an array of TableAction objects.
   *
   * @type {TableAction[]}
   * @name tableActions
   * @inner
   * @readonly
   *
   * @example
   * // Usage
   * const actions = tableActions;
   *
   * @see {@link TableAction}
   */
  const tableActions: TableAction[] = useMemo(() => {
    if (
      [
        UserActivitiesGroupingCriteriaEnum.policy,
        UserActivitiesGroupingCriteriaEnum.hiddenPolicy,
      ].includes(groupingCriteria)
    ) {
      return currentOpenNodeParentTableActions;
    }

    if (
      groupingCriteria === UserActivitiesGroupingCriteriaEnum.none &&
      notificationsToRead > 0
    ) {
      return [
        {
          icon: "CheckmarkRoundedIcon",
          label: t("common.markAllAsRead"),
          onClick: () => {
            activityService
              .markUserActivitiesAsRead()
              .catch(() => {
                createNotification(
                  t("errors.genericUpdateError", {
                    resource: t("common.activities"),
                  }),
                  NotificationTypes.DANGER,
                );
              })
              .then(() => {
                getUserActivities().then(() => {});
              });
          },
        },
      ];
    } else {
      return [];
    }
  }, [createNotification, t, notificationsToRead, groupedTreeItemsTableFields]);

  /**
   * @desc Adds an event listener to the signalRConnection object to listen for the notifiedNewActivity event.
   */
  /*useEffect(() => {
    if (signalRConnection) {
      const handleReceiveMessage = () => {
        /!* if (filters.timespanOption !== TimespanOptionKey.Custom) {
           const { from, to } = getTimestampOptionDates(filters.timespanOption);
           setFilters((prevFilters) => ({
             ...prevFilters,
             dateEnd: convertDateToTicks(to),
             dateStart: convertDateToTicks(from),
           }));
         } else {
           getUserActivities().then(() => {});
         }*!/
      };

      signalRConnection.on(
        SignalREventTypeEnum.notifiedNewActivity,
        handleReceiveMessage,
      );

      // Remove the event listener when the component is unmounted
      return () => {
        signalRConnection.off(
          SignalREventTypeEnum.notifiedNewActivity,
          handleReceiveMessage,
        );
      };
    }
  }, [signalRConnection, location.pathname]);*/

  /**
   * @desc Store filters in sessionStorage when filters change.
   */
  /* useEffect(() => {
     storeStorageItem(
       "sessionStorage",
       StorageItemKeyEnum.userActivitiesFilters,
       filters,
     );
   }, [filters]);
 */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      storeStorageItem(
        "sessionStorage",
        StorageItemKeyEnum.userActivitiesResizablePanelWidth,
        resizablePanelWidth,
      );
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [resizablePanelWidth]);

  /**
   * @desc clear the activityTimeoutRef when the component is unmounted.
   */
  useEffect(() => {
    return () => {
      if (activityTimeoutRef.current) {
        clearTimeout(activityTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    switch (groupingCriteria) {
      case UserActivitiesGroupingCriteriaEnum.none:
        getUserActivities().then(() => {});
        break;
      default:
        break;
    }
  }, [groupingCriteria]);

  useEffect(() => {
    if (groupingCriteria !== UserActivitiesGroupingCriteriaEnum.none) {
      getGroupedPolicyLevel0().then(() => {});
    }
  }, []);

  useEffect(() => {
    if (groupingCriteria === UserActivitiesGroupingCriteriaEnum.none) {
      getUserActivities().then(() => {});
    }
  }, [filters]);

  return {
    activities,
    appliedFiltersCount,
    currentOpenNode,
    dateFormatter,
    detailModalState,
    filters,
    groupedActionModalState,
    groupedFilters,
    groupedTotal,
    groupedTreeItemsTableFields,
    groupingCriteria,
    handleDetailClick,
    handleGroupedItemMenuAction,
    handleGroupedModalActionConfirm,
    handleGroupedTableRowAction,
    handleGroupingCriteriaChange,
    handleResetAdvancedFilters,
    handleTreeItemToggle,
    hideTooltip,
    isLoading,
    isLoadingTree,
    isLoadingTreeItem,
    notificationsToRead,
    resizablePanelWidth,
    selectedActivity,
    selectedGroupedItem,
    setFilters,
    setGroupedFilters,
    setGroupingCriteria,
    setResizablePanelWidth,
    showTooltip,
    t,
    tableActions,
    total,
    treeItems,
  };
}

export default useUserActivitiesTab;
