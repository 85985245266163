import { CalendarDateTime, getLocalTimeZone } from "@internationalized/date";

import { TreeItem } from "../components/Tree";
import { AdminActivityTypeEnum } from "../enum/AdminActivityTypeEnum";
import { PolicySeverityEnum } from "../enum/PolicySeverityEnum";
import { PolicyTypeEnum } from "../enum/PolicyTypeEnum";
import { UserActivityTypeEnum } from "../enum/UserActivityTypeEnum";
import AdminActivityType from "../interfaces/AdminActivityType";
import { ComputerStatus, ComputerStatusDetail } from "../interfaces/Computer";
import Policy from "../interfaces/Policy";
import PolicySeverity from "../interfaces/PolicySeverity";
import { SoftwareActivityTypeEnum } from "../interfaces/SoftwareActivity";
import SoftwareActivityType from "../interfaces/SoftwareActivityType";
import { TimespanOptionKey } from "../interfaces/TimespanOption";
import UserActivityType from "../interfaces/UserActivityType";

import { adminActivityTypes } from "./adminActivityTypes";
import { policies } from "./policies";
import { policySeverities } from "./policySeverities";
import { softwareActivityTypes } from "./softwareActivityTypes";
import { userActivityTypes } from "./userActivityTypes";

/**
 * Generates a UUID (Universally Unique Identifier).
 *
 * @return {string} The generated UUID.
 */
export function generateUUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Returns the color corresponding to the given computer status.
 *
 * @param {ComputerStatus} status - The computer status to get the color for.
 * @return {string} - The color corresponding to the computer status.
 */
export function getComputerStatusDetail(
  status?: ComputerStatus,
): ComputerStatusDetail {
  switch (status) {
    case ComputerStatus.offline:
      return {
        background: "bg-red",
        description: "Offline",
      };
    case ComputerStatus.locked:
      return {
        background: "bg-yellow",
        description: "Locked",
      };
    case ComputerStatus.processing:
      return {
        background: "bg-yellow",
        description: "Processing",
      };
    case ComputerStatus.unlocked:
      return {
        background: "bg-yellow",
        description: "Unlocked",
      };
    case ComputerStatus.connected:
      return {
        background: "bg-green",
        description: "Connected",
      };
    default:
      return {
        background: "bg-dark-gray",
        description: "No status",
      };
  }
}

/**
 * Find a policy by type.
 *
 * @param {PolicyTypeEnum} type - The type of policy to search for.
 * @returns {Policy|undefined} The found policy, or undefined if not found.
 */
export function findPolicyByType(type?: PolicyTypeEnum): Policy | undefined {
  return policies.find((policy) => policy.type === type);
}

/**
 * Find a UserActivity by type.
 *
 * @param {UserActivityTypeEnum} type - The type of policy to search for.
 * @returns {UserActivityType} The found activity type, or undefined if not found.
 */
export function findUserActivityByType(
  type: UserActivityTypeEnum,
): UserActivityType | undefined {
  return userActivityTypes.find((activityType) => activityType.type === type);
}

/**
 * Find a AdminActivity by type.
 *
 * @param {AdminActivityTypeEnum} type - The type of policy to search for.
 * @returns {ExtendedPolicy|undefined} The found admin activity, or undefined if not found.
 */
export function findAdminActivityByType(
  type: AdminActivityTypeEnum,
): AdminActivityType | undefined {
  return adminActivityTypes.find((activityType) => activityType.type === type);
}

/**
 * Finds the policy severity by type.
 *
 * @param {PolicySeverityEnum} type - The type of policy severity.
 * @returns {PolicySeverity | undefined} - The found policy severity or undefined if not found.
 */
export function findPolicySeverityByType(
  type: PolicySeverityEnum,
): PolicySeverity | undefined {
  return policySeverities.find((activityType) => activityType.id === type);
}

/**
 * Find a AdminActivity by type.
 *
 * @param {AdminActivityTypeEnum} type - The type of policy to search for.
 * @returns {ExtendedPolicy|undefined} The found admin activity, or undefined if not found.
 */
export function findSoftwareActivityByType(
  type: SoftwareActivityTypeEnum,
): SoftwareActivityType | undefined {
  return softwareActivityTypes.find(
    (activityType) => activityType.type === type,
  );
}

/**
 * Converts a JavaScript Date object to the equivalent number of ticks.
 *
 * Ticks in .NET are measured as the number of 100-nanosecond intervals
 * that have elapsed since 12:00:00 midnight, January 1, 0001 (in the Gregorian calendar).
 *
 * @param {Date} date - A valid JavaScript Date object.
 * @returns {number} - The equivalent number of ticks.
 */
export const convertDateToTicks = (date: Date): number => {
  // Numero di millisecondi dal 1 gennaio 1970
  const unixMilli = date.getTime();

  // Convertire i millisecondi in tick (1 ms = 10.000 tick)
  const ticks = unixMilli * 10000;

  // Aggiungere i tick dall'epoch .NET (0001-01-01) all'epoch Unix (1970-01-01)
  const epochTicks = 621355968000000000;
  return epochTicks + ticks;
};

/**
 * Converts ticks to CalendarDateTime.
 *
 * @param {number} ticks - Ticks to be converted.
 * @returns {CalendarDateTime} - The converted CalendarDateTime object.
 */
export const convertTicksToCalendarDateTime = (
  ticks: number,
): CalendarDateTime => {
  const epochTicks = 621355968000000000;
  const unixMilli = (ticks - epochTicks) / 10000;
  const date = new Date(unixMilli);
  return new CalendarDateTime(
    getLocalTimeZone(),
    date.getFullYear(),
    date.getMonth() + 1, // Months are 0-based in JavaScript Date
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
};

/**
 * Retrieves the timestamp option dates based on the given option key.
 *
 * @param {TimespanOptionKey} optionKey - The key representing the timespan option.
 * @returns {{ from: Date, to: Date }} - The start and end date of the timespan option.
 */
export function getTimestampOptionDates(optionKey: TimespanOptionKey): {
  from: Date;
  to: Date;
} {
  const now = new Date();
  const from = new Date();

  switch (optionKey) {
    case TimespanOptionKey.LastHour:
      from.setHours(now.getHours() - 1);
      break;
    case TimespanOptionKey.LastSixHours:
      from.setHours(now.getHours() - 6);
      break;
    case TimespanOptionKey.LastTwentyFourHours:
      from.setDate(now.getDate() - 1);
      break;
    case TimespanOptionKey.LastWeek:
      from.setDate(now.getDate() - 7);
      break;
    case TimespanOptionKey.LastTwoWeeks:
      from.setDate(now.getDate() - 14);
      break;
    case TimespanOptionKey.LastMonth:
      from.setMonth(now.getMonth() - 1);
      break;
    case TimespanOptionKey.LastThreeMonths:
      from.setMonth(now.getMonth() - 3);
      break;
    case TimespanOptionKey.LastSixMonths:
      from.setMonth(now.getMonth() - 6);
      break;
    case TimespanOptionKey.LastYear:
      from.setFullYear(now.getFullYear() - 1);
      break;
    default:
      // Default to last hour if none match
      from.setHours(now.getHours() - 1);
  }

  return {
    from,
    to: now,
  };
}

/**
 * Transforms an object into an array of IDs.
 *
 * @param {any} data - The object to be transformed.
 * @return {any} - The transformed object with only the IDs.
 */
export function transformObjectArraysToArrayOfIds(data: any): any {
  const result: any = {};
  Object.keys(data).forEach((key) => {
    if (
      Array.isArray(data[key]) &&
      data[key].length > 0 &&
      "id" in data[key][0]
    ) {
      result[key] = data[key].map((item: any) => item.id);
    } else {
      result[key] = data[key];
    }
  });
  return result;
}

/**
 * Finds the deepest open node in a tree of items.
 *
 * @param {TreeItem[]} items - The array of tree items.
 * @param {number} [currentDepth=0] - The current depth of the items. Defaults to 0.
 * @return {{node: TreeItem | null; depth: number}} - The deepest open node and its depth.
 */
export function findDeepestTreeItemOpenNode(
  items: TreeItem[],
  currentDepth: number = 0,
): {
  node: TreeItem | null;
  depth: number;
} {
  let deepestNode: { node: TreeItem | null; depth: number } = {
    depth: -1,
    node: null,
  };

  for (const item of items) {
    if (item.isOpen) {
      if (currentDepth > deepestNode.depth) {
        deepestNode = { depth: currentDepth, node: item };
      }
    }
    if (item.children && item.children.length > 0) {
      const childDeepestNode = findDeepestTreeItemOpenNode(
        item.children,
        currentDepth + 1,
      );
      if (childDeepestNode.depth > deepestNode.depth) {
        deepestNode = childDeepestNode;
      }
    }
  }

  return deepestNode;
}

/**
 * Splits a given string by a specified separator and returns the last element.
 *
 * @param {string} str - The string to be split.
 * @param {string} separator - The separator used for splitting the string.
 * @return {string} - The last element after splitting the string.
 */
export function splitAndGetLastElement(
  separator: string,
  str?: string,
): string {
  if (!str) {
    return "";
  }

  const split = str.split(separator);

  if (split.length === 0) {
    return "";
  }
  return split[split.length - 1];
}

export function normalizePath(path: string): string {
  return path
    .split("\\")
    .filter((part) => part !== "")
    .join("\\")
    .trim();
}
