import clsx from "clsx";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import type { AriaTextFieldProps } from "react-aria";
import { useTextField } from "react-aria";

import { Icon } from "./Icon";

interface TextFieldProps extends AriaTextFieldProps {
  required?: boolean;
  variant?: "primary" | "secondary";
  invalid?: boolean;
  title?: string;
}

/**
 * Combines an external ref and an internal ref to synchronize their values.
 *
 * @template T - The type of the ref value.
 * @param {React.Ref<T>} externalRef - The external ref.
 * @param {React.RefObject<T>} internalRef - The internal ref.
 * @returns {React.RefObject<T>} - The internal ref, after synchronization with the external ref.
 */
function useCombinedRefs<T>(
  externalRef: React.Ref<T>,
  internalRef: React.RefObject<T>,
): React.RefObject<T> {
  useEffect(() => {
    if (!externalRef) return;

    if (typeof externalRef === "function") {
      externalRef(internalRef.current);
    } else if (
      externalRef &&
      "current" in externalRef &&
      internalRef.current !== null
    ) {
      const mutableRef = externalRef as React.MutableRefObject<T>;
      mutableRef.current = internalRef.current;
    }
  }, [externalRef, internalRef]);

  return internalRef;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    label,
    description,
    errorMessage,
    value,
    onChange,
    type,
    variant = "primary",
    title,
    invalid = false,
  } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const internalRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombinedRefs(ref, internalRef);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(
      { ...props, onChange, type: isPasswordVisible ? "text" : type, value },
      combinedRef,
    );

  /**
   * Sets focus on the specified HTML label element.
   *
   * @param {React.MouseEvent<HTMLLabelElement>} event - The mouse event triggered by clicking the label.
   */
  const focusInput = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    combinedRef?.current?.focus();
  };
  return variant === "primary" ? (
    <div
      className={clsx(
        "relative w-full rounded-[6px]",
        props.isReadOnly && "bg-gray",
        props.isDisabled && "pointer-events-none opacity-50",
      )}
    >
      <input
        title={title}
        className="peer h-[40px] w-full rounded-[6px] border border-gray bg-transparent px-3 pt-3 font-sans text-sm font-normal text-extra-dark-gray outline outline-0 transition-all duration-100 ease-in-out placeholder-shown:border invalid:border-dark-red focus:outline-0 disabled:pointer-events-none disabled:cursor-not-allowed"
        required={props.required}
        maxLength={props.maxLength || 250}
        {...(inputProps as Record<string, unknown>)}
        placeholder=" "
        ref={combinedRef}
      />
      {label && (
        <label
          {...labelProps}
          htmlFor={props.id}
          onClick={focusInput}
          className="peer-focus:text-blue-600 peer-focus:dark:text-blue-500 absolute start-3 top-3 z-10 origin-[0] -translate-y-2.5 scale-75 transform text-sm text-dark-gray duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2.5 peer-focus:scale-75 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4"
        >
          {label}
        </label>
      )}
      {props.isInvalid && errorMessage && (
        <p {...errorMessageProps} className="p-2 text-xs text-red">
          {JSON.stringify(errorMessage)}
        </p>
      )}
    </div>
  ) : (
    <div className="flex w-full flex-col">
      {label && (
        <label className="pb-2 text-dark-blue" {...labelProps}>
          {label}
        </label>
      )}

      <div className="relative">
        <input
          title={title}
          className={clsx(
            "w-full rounded-md border border-gray bg-white px-4 py-4 text-extra-dark-gray outline-none focus:border-dark-gray",
            type === "password" && "pr-10",
            invalid && "border-red",
          )}
          required={props.required}
          {...(inputProps as Record<string, unknown>)}
          ref={ref}
        />

        {type === "password" && (
          <button
            type="button"
            aria-label="Toggle password visibility"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            className="absolute inset-y-0 right-2 flex cursor-pointer items-center text-dark-blue"
          >
            <Icon
              name={isPasswordVisible ? "ViewPasswordIcon" : "HidePasswordIcon"}
            />
          </button>
        )}
      </div>

      {description && <p {...descriptionProps}>{description}</p>}
      {errorMessage && (
        <p {...errorMessageProps} className="mt-1 text-medium-red">
          {JSON.stringify(errorMessage)}
        </p>
      )}
    </div>
  );
});

TextField.displayName = "TextField";

export default TextField;
